module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://src.azariangrowthagency.com/graphql","schema":{"timeout":3600000,"perPage":10,"requestConcurrency":2,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"html":{"createStaticFiles":false,"useGatsbyImage":false,"generateWebpImages":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"localFile":{"maxFileSizeBytes":10,"excludeByMimeTypes":[],"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter WordPress Blog","short_name":"GatsbyJS & WP","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3f46304a785171c43671cd83f5d77006"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(min-width: 576px)","md":"(min-width: 768px)","lg":"(min-width: 992px)","xl":"(min-width: 1200px)","xxl":"(min-width: 1400px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M3H8JNR","includeInDevelopment":true,"enableWebVitalsTracking":true,"selfHostedOrigin":"https://ssgtm.azariangrowthagency.com/","gtmAuth":"fMDeUCY6qmXzYk0HRqhGRA","gtmPreview":"env-1","defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
